import React, { useEffect, useState, useContext } from "react";
import { Navbar, Nav, NavDropdown, Container, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { data } from "../../config/config.js";
import { BtnLoggin } from "./BtnLoggin";
import { marcasContext } from "./../Context/MarcasContext";
import { globalContext } from "./../Context/GlobalContext";
import "./Navbar.css";
import Buscador from "../Buscador/Buscador.jsx";
import { authContext } from "../Context/AuthContext.js";
import ButtonTranslate from "../ButtonTranslate/ButtonTranslate.js";
import { useTranslation } from "react-i18next";
import { getCategorias } from "../../services/getCategorias.jsx";

const apiProductos = process.env.REACT_APP_API_PRODUCTOS;




export const Barra1 = () => {
  const { setCodigoMarca } = useContext(marcasContext);
  const { user } = useContext(authContext);
  const country = process.env.REACT_APP_COUNTRY;

  const [marcasMapeadas, setMarcasMapeadas] = useState([]);

  const [codPath, setCodPath] = useState('');
  const [manPath, setManPath] = useState('');
  const [procPath, setProcPath] = useState('');

  // Logica para las categorias
  const [categorias, setCategorias] = useState(null);
  const [error, setError] = useState(null);
  const { darkMode } = useContext(globalContext);
  const [hoveredCategories, setHoveredCategories] = useState({});
  const navigate = useNavigate();
  const [show, setShow] = useState(false)


  const handleMouseEnter = (categoriaCodigo) => {
    setHoveredCategories((prevHovered) => ({
      ...prevHovered,
      [categoriaCodigo]: true,
    }));
  };

  const handleMouseLeave = (categoriaCodigo) => {
    setHoveredCategories((prevHovered) => ({
      ...prevHovered,
      [categoriaCodigo]: false,
    }));
  };

  const handleClick = (categoria) => {
    navigate(`/categorias/${categoria.Codigo}`)
  };

  const renderSubcategories = (subcategories) =>
    subcategories.map((subcategory) => (
      <div key={subcategory.Codigo}>
        {subcategory.Hijos.length > 0 ? (
          <Dropdown
            show={hoveredCategories[subcategory.Codigo]}
            onMouseEnter={() => handleMouseEnter(subcategory.Codigo)}
            onMouseLeave={() => handleMouseLeave(subcategory.Codigo)}
          >
            <Dropdown.Toggle variant="dark" id="navbarScrollingDropdown" onClick={() => handleClick(subcategory)}>
              {subcategory.Descripcion.toUpperCase()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {renderSubcategories(subcategory.Hijos)}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Link
            to={`/categorias/${subcategory.Codigo}`}
            className="dropdown-item"
            key={subcategory.Codigo}
            onClick={() => setShow(false)}
          >
            {subcategory.Descripcion.toUpperCase()}
          </Link>
        )}
      </div>
    ));

  useEffect(() => {
    const fetchCategorias = async (retryCount = 0) => {
      try {
        const storedCategorias = localStorage.getItem('categorias-stored');
        if (storedCategorias) {
          setCategorias(JSON.parse(storedCategorias));
        } else {
          const categoriasData = await getCategorias();
          setCategorias(categoriasData);
          localStorage.setItem('categorias-stored', JSON.stringify(categoriasData));
        }
      } catch (err) {
        if (retryCount < 3) {
          if (err?.response?.status === 500) {
            console.log("Error en el servidor.");
            return err
          } else if (err?.response?.status === 429) {
            console.log("Error código 429 demasiados intentos, aguarde.");
            setTimeout(() => fetchCategorias(retryCount + 1), 60000); // 1 minutos
          } else {
            setError(err);
            console.error('Error al obtener los datos:', err);
            setTimeout(() => fetchCategorias(retryCount + 1), 60000); // 1 minutos
          }
        } else {
          console.error('Se alcanzó el máximo de intentos. No se pudo obtener los datos:', err);
        }
      }
    };

    fetchCategorias();
  }, []);


  // Finalizo logica para las categorias

  const getMarcas = async () => {
    await axios(`${apiProductos}/marcas`)
      .then((res) => {
        setMarcasMapeadas(res.data);
        setCodigoMarca(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMarcas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const country = process.env.REACT_APP_COUNTRY;
    let path = '';

    if (country === "PY") {
      path = "/pdf/politica-anticorrupcion/Paraguay/CodPY.pdf";
    } else if (country === "UY") {
      path = "/pdf/politica-anticorrupcion/Uruguay/CodUY.pdf";
    } else if (country === "HN") {
      path = "/pdf/politica-anticorrupcion/Honduras/CodHN.pdf";
    } else if (country === "USA" && country === "JM" && country === "PR" && country === "TT") {
      path = "/pdf/politica-anticorrupcion/USA/CodUSA.pdf";
    } else if (country === "SV") {
      path = "/pdf/politica-anticorrupcion/ElSalvador/CodSV.pdf";
    } else if (country === "GT") {
      path = "/pdf/politica-anticorrupcion/Guatemala/CodGT.pdf";
    } else if (country === "DO") {
      path = "/pdf/politica-anticorrupcion/RepublicaDominicana/CodDO.pdf";
    } else if (country === "CR") {
      path = "/pdf/politica-anticorrupcion/CostaRica/CodCR.pdf";
    } else if (country === "CL") {
      path = "/pdf/politica-anticorrupcion/Chile/CodCL.pdf";
    }

    setCodPath(path);
  }, []);

  useEffect(() => {
    const country = process.env.REACT_APP_COUNTRY;
    let path = '';

    if (country === "PY") {
      path = "/pdf/politica-anticorrupcion/Paraguay/ManPY.pdf";
    } else if (country === "UY") {
      path = "/pdf/politica-anticorrupcion/Uruguay/ManUY.pdf";
    } else if (country === "HN") {
      path = "/pdf/politica-anticorrupcion/Honduras/ManHN.pdf";
    } else if (country === "USA" && country === "JM" && country === "PR" && country === "TT") {
      path = "/pdf/politica-anticorrupcion/USA/ManUSA.pdf";
    } else if (country === "SV") {
      path = "/pdf/politica-anticorrupcion/ElSalvador/ManSV.pdf";
    } else if (country === "GT") {
      path = "/pdf/politica-anticorrupcion/Guatemala/ManGT.pdf";
    } else if (country === "DO") {
      path = "/pdf/politica-anticorrupcion/RepublicaDominicana/ManDO.pdf";
    } else if (country === "CR") {
      path = "/pdf/politica-anticorrupcion/CostaRica/ManCR.pdf";
    } else if (country === "CL") {
      path = "/pdf/politica-anticorrupcion/Chile/ManCL.pdf";
    }

    setManPath(path);
  }, []);

  useEffect(() => {
    const country = process.env.REACT_APP_COUNTRY;
    let path = '';

    if (country === "PY") {
      path = "/pdf/politica-anticorrupcion/Paraguay/ProcPY.pdf";
    } else if (country === "UY") {
      path = "/pdf/politica-anticorrupcion/Uruguay/ProcUY.pdf";
    } else if (country === "HN") {
      path = "/pdf/politica-anticorrupcion/Honduras/ProcHN.pdf";
    } else if (country === "USA" && country === "JM" && country === "PR" && country === "TT") {
      path = "/pdf/politica-anticorrupcion/USA/ProcUSA.pdf";
    } else if (country === "SV") {
      path = "/pdf/politica-anticorrupcion/ElSalvador/ProcSV.pdf";
    } else if (country === "GT") {
      path = "/pdf/politica-anticorrupcion/Guatemala/ProcGT.pdf";
    } else if (country === "DO") {
      path = "/pdf/politica-anticorrupcion/RepublicaDominicana/ProcDO.pdf";
    } else if (country === "CR") {
      path = "/pdf/politica-anticorrupcion/CostaRica/ProcCR.pdf";
    } else if (country === "CL") {
      path = "/pdf/politica-anticorrupcion/Chile/ProcCL.pdf";
    }

    setProcPath(path);
  }, []);


  const [t, i18n] = useTranslation("global")


  return (
    <div className="bordeBarraNav">
      <Navbar
        expand="lg"
        className={`barra ${darkMode ? "darkNav" : "lightNav"}`}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            {" "}
            <Link to="/">
              <img
                src={data.url_brand}
                alt="Logo de la pagina"
                className="setlogo"
                width={100}
                height='auto'
              ></img>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ height: "100%" }}>

              {country != "JM" && country != "TT" && country != "PR" &&
                <NavDropdown id="navbarScrollingDropdown"
                  title={t("navbar.categorias")}
                  style={{ textTransform: "uppercase" }}
                  show={show}
                  onClick={() => setShow(!show)}
                  onHide={() => setShow(false)}>
                  <div className="container">
                    <div className={`dropdown`}>
                      {categorias && Array.isArray(categorias) ? (
                        categorias.map((categoria) => (
                          <li
                            className={`dropdown dropend ${hoveredCategories[categoria.Codigo] ? "show" : ""
                              }`}
                            key={categoria.Codigo}
                          >
                            {categoria.Hijos.length > 0 ? (
                              <Dropdown
                                show={hoveredCategories[categoria.Codigo]}
                                onMouseEnter={() => handleMouseEnter(categoria.Codigo)}
                                onMouseLeave={() => handleMouseLeave(categoria.Codigo)}
                              >
                                <Dropdown.Toggle variant="dark" id="navbarScrollingDropdown" onClick={() => handleClick(categoria)}>
                                  {categoria.Descripcion.toUpperCase()}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {renderSubcategories(categoria.Hijos)}
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              <Link
                                to={`/categorias/${categoria.Codigo}`}
                                className="dropdown-item"
                                onClick={() => setShow(false)}
                              >
                                {categoria.Descripcion.toUpperCase()}
                              </Link>
                            )}
                          </li>
                        ))
                      ) : (
                        <li>{t("navbar-categorias-no-disponibles")}</li>
                      )}
                    </div>
                  </div>
                </NavDropdown>
              }

              {country != "JM" && country != "TT" && country != "PR" &&
                <NavDropdown
                  title={t("navbar.marcas")}
                  style={{ textTransform: "uppercase" }}
                  id="navbarScrollingDropdown"
                  onClick={() => setShow(false)} >
                  {marcasMapeadas.map((link) => {
                    return (
                      <NavDropdown.Item key={link.Codigo}>
                        <Link
                          rel="prefetch"
                          to={`/marcas/${link.Codigo}`}
                          className="dropdown-item mod"
                        >
                          {link.Descripcion}
                        </Link>
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>}

              <NavDropdown
                title={t("navbar.nosotros")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
                onClick={() => setShow(false)}>
                <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="nuestraempresa" className="dropdown-item mod">
                    {t("navbar.subitem-la-empresa")}
                  </Link>
                </NavDropdown.Item>

                {/* <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="/empleados" className="dropdown-item mod">
                    STAFF
                  </Link>
                </NavDropdown.Item> */}
              </NavDropdown>


              <NavDropdown
                title={t("navbar.links-utiles")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
                onClick={() => setShow(false)}>
                <NavDropdown.Item>
                  <Link rel="prefetch" to="/rma" className="dropdown-item mod">
                    {t("navbar.subitem-rma")}
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link rel="prefetch" to="/selectorcatalogo" className="dropdown-item mod">
                    {t("navbar.subitem-catalogo-regional")}
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link rel="prefetch" to="/capacitaciones" className="dropdown-item mod">
                    {t("navbar.subitem-capacitaciones")}
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link rel="prefetch" to="/Iquote" className="dropdown-item mod">
                    {t("navbar.subitem-iquotehpe")}
                  </Link>
                </NavDropdown.Item>

                {country === "USA" && (
                  <>
                    <NavDropdown.Item>
                      <Link rel="prefetch" to="/wireless" className="dropdown-item mod">
                        Wireless
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link rel="prefetch" to="/iptelephony" className="dropdown-item mod">
                        IP Telephony
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link rel="prefetch" to="/infraestructure" className="dropdown-item mod">
                        Infraestructure
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link rel="prefetch" to="/networking" className="dropdown-item mod">
                        Networking, servers & Storage
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link rel="prefetch" to="/digitalsecurity" className="dropdown-item mod">
                        Digital security
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="https://displaysolutions.samsung.com/support/tools/videowall-configurator" target="_blank">
                      Video wall configurator
                    </NavDropdown.Item>

                    <NavDropdown.Item >
                      <Link rel="prefetch" to="/Iquote" className="dropdown-item mod">
                        IQuote HP
                      </Link>
                    </NavDropdown.Item>
                  </>
                )}

                {/* <NavDropdown.Item><Link to="/legajo" className='dropdown-item mod'>LEGAJO IMPOSITIVO</Link></NavDropdown.Item> */}

                <NavDropdown
                  title={t("navbar.subitem-politica")}
                  style={{ textTransform: "uppercase" }}
                  id="navbarScrollingDropdown">

                  <NavDropdown.Item href={procPath} target="_blank">
                    {t("navbar.politicas1")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={codPath} target="_blank">
                    {t("navbar.politicas2")}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={manPath} target="_blank">
                    {t("navbar.politicas3")}
                  </NavDropdown.Item>
                  <NavDropdown.Item>

                    <Link rel="prefetch" to="/formularioDenuncia" className="dropdown-item mod">
                      {t("navbar.politicas4")}
                    </Link>

                  </NavDropdown.Item>

                </NavDropdown>

              </NavDropdown>

              <NavDropdown
                title={t("navbar.sedes")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
                onClick={() => setShow(false)}>
                <NavDropdown.Item
                  href="https://www.solutionbox.com.ar/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/argentina.png"
                    }
                    alt="Bandera Arg"
                    className="setBanderas"
                  ></img>
                  ARGENTINA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionbox.cl/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/chile.png"
                    }
                    alt="Bandera Chile"
                    className="setBanderas"
                  ></img>
                  CHILE
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionboxusa.com/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Banderas/estadosunidos.png"
                    }
                    alt="Bandera USA"
                    className="setBanderas"
                  ></img>
                  USA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionbox.com.uy/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/uruguay.png"
                    }
                    alt="Bandera Uruguay"
                    className="setBanderas"
                  ></img>
                  URUGUAY
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionbox.com.do/"
                  target="_blank"
                  className="dominicana"
                >
                  {" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Banderas/Republicadominicana.png"
                    }
                    alt="Bandera Rep.Dominicana"
                    className="setBanderas"
                  ></img>
                  R.DOMINICANA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionbox.cr/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/costarica.png"
                    }
                    alt="Bandera Costa Rica"
                    className="setBanderas"
                  ></img>
                  COSTA RICA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.solutionbox.com.hk/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/hongkong.png"
                    }
                    alt="Bandera Hong Kong"
                    className="setBanderas"
                  ></img>
                  HONG KONG
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://www.solutionbox.com.pa/"
                  target="_blank"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/Banderas/panama.png"}
                    alt="Bandera Panama"
                    className="setBanderas"
                  ></img>
                  PANAMA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://www.solutionbox.com.hn/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/honduras.png"
                    }
                    alt="Bandera Honduras"
                    className="setBanderas"
                  ></img>
                  HONDURAS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://www.solutionbox.com.sv/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/elsalvador.png"
                    }
                    alt="Bandera El Salvador"
                    className="setBanderas"
                  ></img>
                  EL SALVADOR
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://www.solutionbox.com.gt/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/guatemala.png"
                    }
                    alt="Bandera Guatemala"
                    className="setBanderas"
                  ></img>
                  GUATEMALA
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="http://www.solutionbox.com.py/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/paraguay.png"
                    }
                    alt="Bandera Paraguay"
                    className="setBanderas"
                  ></img>
                  PARAGUAY
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="http://www.solutionbox.com.tt/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/tt.png"
                    }
                    alt="Bandera tt"
                    className="setBanderas"
                  ></img>
                  Tr. y Tobago
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="http://www.solutionbox.com.pr/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/puertorico.png"
                    }
                    alt="Bandera Puerto Rico"
                    className="setBanderas"
                  ></img>
                  Puerto Rico
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="http://www.solutionbox.com.jm/"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/Banderas/jamaica.png"
                    }
                    alt="Bandera Jamaica"
                    className="setBanderas"
                  ></img>
                  Jamaica
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={t("navbar.contacto")}
                style={{ textTransform: "uppercase" }}
                id="navbarScrollingDropdown"
                onClick={() => setShow(false)}>
                <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="contacto" className="dropdown-item mod">
                    {t("navbar.contacto1")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link rel="prefetch" to="trabajaconnosotros" className="dropdown-item mod">
                    {t("navbar.contacto2")}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              {country === "UY" &&
                <Nav.Item className="ofertas-navlink-container">
                  <Nav.Link style={{ textTransform: "uppercase" }} href="/ofertas" className="ofertas-navlink">
                    {t("navbar.ofertas")}
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <Buscador />

            <ButtonTranslate />
            {!user ? <div className="mobileBtnLogin">
              <BtnLoggin />
            </div> : <BtnLoggin />}


          </Navbar.Collapse>



        </Container>
      </Navbar>
    </div>
  );
};

