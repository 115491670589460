const country = process.env.REACT_APP_COUNTRY;

export const data = require('./countries.json')[country];

export const employees = require('./empleados.json');

export const banners = require('./banners.json');

export const capacitaciones = require('./capacitaciones.json');


