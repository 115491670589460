import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AltaUsuarios } from './components/pages/AltaUsuarios';
import { ApiMap } from './components/ApiMarcas/ApiMap';
import { Barra1 } from './components/Navbar/Navbar';
import { Capacitaciones } from './components/pages/Capacitaciones';
import Cart from './components/Carrito/Cart'
import { ChangePassword } from './components/Login/ChangePassword'
import Checkout from './components/Checkout/Checkout';
import { Contacto } from './components/pages/Contacto';
import { CookiesPolicy } from './components/helpers/CookiesPolicy'
import DetalleDeProducto from './components/DetalleDeProducto/DetalleDeProducto';
import { DetallesCompra } from "./components/Profile/DetallesCompra";
// import { EmpleadosLista } from './components/EmpleadosLista/EmpleadosLista';
import { ErrorForm } from "./components/Formulario/ErrorForm";
import { Footer } from './components/Footer/Footer';
import { FormSuccess } from './components/Formulario/FormSuccess';
import { HomePage } from './components/HomePage/HomePage';
import NewLogin from './components/Login/NewLogin';
import { Newsletter } from './components/pages/Newsletter';
import { NotFound } from './components/helpers/NotFound'
import { NuestraEmpresa } from './components/pages/NuestraEmpresa';
import { Profile } from './components/Profile/Profile'
import RegistroUsuario from './components/pages/RegistroUsuario';
import { RmaItemFallado } from './components/pages/RmaItemFallado';
import { SelectorRma } from './components/pages/SelectorRma';
import { Success } from './components/Checkout/Success'
import { TrabajaConNosotros } from './components/pages/TrabajaConNosotros';
import { Iquote } from './components/iquote/Iquote'
import { ScrollButton } from './components/ScrollButton/ScrollButton';
import { PageForgotPass } from './components/ForgotPass/PageForgotPass';
import { RmaApc } from './components/pages/RmaApc';
import FormularioDenuncia from './components/pages/FormularioDenuncia';
import { RmaGoPro } from './components/pages/RmaGoPro';
import { Legajo } from './components/pages/Legajo';
import { ToastContainer } from 'react-toastify';
import RmaForm from './components/pages/RmaForm';
import GlobalContext from './components/Context/GlobalContext';
import CartContext from './components/Context/CartContext';
import AuthContext from './components/Context/AuthContext';
import MarcasContext from './components/Context/MarcasContext';
import { useEffect, useState } from 'react';
import { ApiCategorias } from './components/Categorias/ApiCategorias';
import ButtonWhatsapp from './components/ButtonWhatsapp/ButtonWhatsapp';
import OpenBox from './screens/Openbox/Openbox';
import Ofertas from './screens/Ofertas/Ofertas';
import Wireless from './components/pages/Tools/Wireless';
import IpTelephony from './components/pages/Tools/IpTelephony';
import Infraestructure from './components/pages/Tools/Infraestructure';
import Networking from './components/pages/Tools/Networking';
import DigitalSecurity from './components/pages/Tools/DigitalSecurity';
import LocalSecurity from './components/pages/Tools/LocalSecurity';

import { PdfCatalogo } from './components/pages/PdfCatalogo';
import { PdfCatalogoEng } from './components/pages/PdfCatalogoEng';
import { SelectorCatalogo } from './components/pages/SelectorCatalogo';


function App() {
  const [countryMetadata, setCountryMetadata] = useState({});
  const country = process.env.REACT_APP_COUNTRY;

  useEffect(() => {
    const metadata = {
      UY: {
        title: 'Solution Box Uruguay - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Uruguay',
      },
      CL: {
        title: 'Solution Box Chile - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Chile',
      },
      DO: {
        title: 'Solution Box República Dominicana - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box República Dominicana',
      },
      PA: {
        title: 'Solution Box Panama - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Panama',
      },
      GT: {
        title: 'Solution Box Guatemala - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Guatemala',
      },
      SV: {
        title: 'Solution Box El Salvador - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box El Salvador',
      },
      HN: {
        title: 'Solution Box Honduras - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Honduras',
      },
      PY: {
        title: 'Solution Box Paraguay - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Paraguay',
      },
      CR: {
        title: 'Solution Box Costa Rica - Mayorista de informatica y telecomunicaciones',
        description: 'Sitio web Solution Box Costa Rica',
      },
      USA: {
        title: 'Solution Box LLC - IT Distributos',
        description: 'Sitio web Solution Box USA',
      },
    };

    // Actualizo la metadata
    setCountryMetadata(metadata[country] || {});
  }, [country]);

  useEffect(() => {
    // Ahora actualizo el html
    document.title = countryMetadata.title || 'Solution Box';

    // Actualizo otras etiquetas meta según la metadata del país
    updateMetaTags(countryMetadata);

  }, [countryMetadata]);

  const updateMetaTags = (metadata) => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', metadata.description || 'Descripción predeterminada');
    }
  };

  const tieneBoton = country === "USA"|| country === "SV" || country === "PA" || country === "HN" || country === "CR" || country === "GT" || country === "DO" ? true : false;

  return (
    <BrowserRouter>
      <GlobalContext>
        <AuthContext>
          <CartContext>
            <MarcasContext>
              <Barra1 />
              <ScrollButton />
              {tieneBoton && <ButtonWhatsapp />}
              <ToastContainer
                position="top-right"
                autoClose={3}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <ToastContainer />
              <Routes>
                <Route path="/" element={<HomePage />} />
                {/* <Route path="/empleados" element={<EmpleadosLista />} /> */}
                {/* <Route path="/empleados/:catId" element={<EmpleadosLista />} /> */}
                <Route path='/newsletter' element={<Newsletter />} />
                <Route path='/nuestraempresa' element={<NuestraEmpresa />} />
                <Route path='/trabajaconnosotros' element={<TrabajaConNosotros />} />
                <Route path='/cvcommit/fail' element={<ErrorForm />} />
                <Route path='/cvcommit/success/:id' element={<FormSuccess />} />
                <Route path='/contacto' element={<Contacto />} />
                <Route path='capacitaciones' element={<Capacitaciones />} />
                <Route path='rma2' element={<RmaForm />} />
                <Route path='rma' element={<SelectorRma />} />
                <Route path='rma3' element={<RmaItemFallado />} />
                <Route path="/marcas/:id" element={<ApiMap />} />
                <Route path="/:sku" element={<DetalleDeProducto />} />
                <Route path="/altaUsuarios" element={<AltaUsuarios />} />
                <Route path="/registroUsuario" element={<RegistroUsuario />} />
                <Route path="/testmobile" element={<ApiMap />} />
                <Route path='/categorias/:codigo' element={<ApiCategorias />} />
                <Route path="/cart" element={<Cart />} />
                <Route path='/checkout' element={<Checkout />} />
                <Route path='/success' element={<Success />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/profile/pedidos/:pedido' element={<DetallesCompra />} />
                <Route path='/login' element={<NewLogin />} />
                <Route path='/newlogin' element={<NewLogin />} />
                <Route path='/Iquote' element={<Iquote />} />
                <Route path='/cambiar-contrasenia' element={<ChangePassword />} />
                <Route path='/cookies-policy' element={<CookiesPolicy />} />
                <Route path='/forgotPassword' element={<PageForgotPass />} />
                <Route path='/rma-apc' element={<RmaApc />} />
                <Route path='/rma-gopro' element={<RmaGoPro />} />
                <Route path='/formularioDenuncia' element={<FormularioDenuncia />} />
                <Route path='*' element={<NotFound />} />
                <Route path="/legajo" element={<Legajo />} />
                <Route path="/ofertas" element={<Ofertas />} />
                <Route path="/openbox" element={<OpenBox />} />
                <Route path="/wireless" element={<Wireless />} />
                <Route path="/iptelephony" element={<IpTelephony />} />
                <Route path="/infraestructure" element={<Infraestructure />} />
                <Route path="/networking" element={<Networking />} />
                <Route path="/localsecurity" element={<LocalSecurity />} />
                <Route path="/digitalsecurity" element={<DigitalSecurity />} />

                <Route path='/catalogo' element={<PdfCatalogo />} />
                <Route path="/catalogueEng" element={<PdfCatalogoEng/>} />
                <Route path="/selectorcatalogo" element={<SelectorCatalogo/>} />


              </Routes>
            </MarcasContext>
          </CartContext>
        </AuthContext>
      </GlobalContext>
      <Footer />
      <ScrollButton />
    </BrowserRouter>
  );
}
export default App;
