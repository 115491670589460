import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useLocation } from "react-router-dom";

import { Unauthorized } from '../helpers/Unauthorized'
import { authContext } from '../Context/AuthContext';

export const Success = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useContext(authContext);

    if (!token) return <Unauthorized />

    const props = location;
    console.log('👉 props ▶', props);
    const compra = props.state.param.pedidoFinal;
    console.log('👉 pedido final ▶', compra);

    const goToDetails = (param, id) => {
        navigate(`/profile/pedidos/${id}`, {
            state: {
                param
            }
        });
    }

    return (
        <div className='divSuccess'>
            <h1>Muchas gracias {compra.name_} por su compra</h1>
            <h4>Su pedido <span>{compra.pedido}/01</span> ha sido creado y procesado con éxito</h4>
            <h4>El vendedor asignado se comunicará con Ud. a fin de coordinar la entrega de la compra</h4>
            <Button variant="secondary" onClick={() => navigate('/')}>Volver al inicio</Button>
        </div>
    )
}
