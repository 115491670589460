import {useNavigate} from 'react-router-dom'
import { Button } from 'react-bootstrap';

import './helpers.css'
import { SpinnerGif } from './SpinnerGif';

export const BadGateway = () =>{

    const navigate = useNavigate();

    return(
        <div id="divBadGateway">
            <SpinnerGif/>
            <h1>Cargando</h1>
            <h5>Estamos cargando sus datos</h5>
            <h5>Aguarde</h5>
            <Button onClick={()=>navigate(-1)}>Volver atrás</Button>
        </div>
    )
}