import React from 'react'
import "./BannerStyle.css"


export const BannerKodak = () => {
  return (
        <div className='container contenedorKodak' >
            <img src={process.env.PUBLIC_URL + "banner/bannerKodak.png"} alt="Imagen marcas" className='imgKodak' loading='lazy'></img>
        </div>
  )
}