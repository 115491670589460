import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import './Historial.css';

export const Historial = ({ compras }) => {
  const navigate = useNavigate();

  const goToDetails = (param, id) => {
    navigate(`/profile/pedidos/${id}`, {
      state: {
        param,
      },
    });
  };

  if (!compras)
    return (
      <div className="divCompras empty-history">
        <h3>Historial de compras vacío</h3>
      </div>
    );

  // Ordenar la lista de compras por fecha de forma descendente
  const comprasOrdenadas = [...compras].sort((a, b) => {
    const fechaA = new Date(a.created_at).getTime();
    const fechaB = new Date(b.created_at).getTime();
    return fechaB - fechaA;
  });

  return (
    <div className="historial-container">
      <h3 className="historial-title">Historial de pedidos realizados en la web</h3>
      <Table responsive striped bordered className="tablaHistorial">
        <thead>
          <tr>
            <th>Número de pedido</th>
            <th>Precio total</th>
            <th>Fecha de operacion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {comprasOrdenadas.map((item) => {
            // Formatear la fecha y hora en el formato deseado
            const fechaFormateada = new Date(item.created_at).toLocaleString('es-ES', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            });

            return (
              <tr key={item.pedido}>
                <td>{item.pedido}/01</td>
                <td>U$D {item.precioTotal}</td>
                <td> {fechaFormateada}</td>
                <td className="button-cell">
                  <Button
                    className="ver-detalle-button"
                    variant="secondary"
                    onClick={() => goToDetails(item, item.pedido)}
                  >
                    Ver detalle
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
