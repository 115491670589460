import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './Checkout.css';
import { useTranslation } from 'react-i18next';

export const Facturacion = ({ user, info, enviarOrden, infoInputs }) => {

  const [defaultValue, setDefaultValue] = useState('Retira personalmente');
  const [defaultValueUsa, setDefaultValueUsa] = useState('Pickup');

  useEffect(() => {
    infoInputs({ target: { name: 'entrega', value: country === "USA" ? defaultValueUsa : defaultValue } });
  }, [defaultValue]);

  // Establecer un valor predeterminado para info.entrega y info.pago
  if (!info.entrega) {
    info.entrega = 'Retira personalmente'; // Valor predeterminado para entrega
  }

  if (!info.pago) {
    info.pago = 'efectivo'; // Valor predeterminado para pago
  }

  const country = process.env.REACT_APP_COUNTRY;
  let rucPais = ""

  if (country === "PY") {
    rucPais =
      "RUC"
  } else {
    rucPais =
      "DNI/CUIL"
  }
  if (country === "USA") {
    info.pago = "PRE PAY"
    info.entrega = "Pickup"
  }


  const [t, i18next] = useTranslation("global")

  return (
    <>
      <div className='card-body cardBody'>
        <h3>{t("facturacion.datos")}</h3>
        <form onSubmit={enviarOrden}>
          <div className='datosForm'>
            <p><b>{t("facturacion.nombre")}:</b> {user.Nombre}</p>
            <p><b>{t("facturacion.apellido")}: </b>{user.Apellido}</p>
            <p><b>{t("formulario.label-2")}: </b>{user.Email}</p>
            <p><b>{rucPais}: </b>{user.Cuit}</p>
          </div>
          <div className='opcionesForm'>
            <label htmlFor='entrega'>{t("facturacion.opciones-entrega")}</label>
            <select disabled value={info.entrega} name='entrega' style={{ width: '100%' }} onChange={(e) => setDefaultValue(e.target.value)}>
              <option value='Retira personalmente' selected>{t("facturacion.toma-condicion")}</option>
              <option style={{ display: 'none' }}></option> {/* Valor predeterminado oculto */}
            </select>
          </div>
          <div className='datosForm'>
            <hr />
            <h3>{t("facturacion.direccion-declarada")}</h3>
            <h6> {t("facturacion.podra-coordinar")} </h6>
            <br />
            <p><b>{t("formulario.direccion")}: </b>{user.Domicilio_facturacion.Domicilio}</p>
            <p><b>{t("facturacion.codigo-postal")}: </b>{user.Domicilio_facturacion.Codigo_postal || 'No informado'}</p>
            <p><b>{t("facturacion.localidad")}: </b>{user.Domicilio_facturacion.Localidad}</p>
            <p><b>{t("facturacion.pais")}: </b>{user.Domicilio_facturacion.Pais}</p>
            <hr />
          </div>
          <div className='opcionesForm'>
            <label htmlFor='pago'>{t("facturacion.opciones-pago")}</label>
            <select disabled value={info.pago} name='pago' onChange={(e) => setDefaultValue(e.target.value)}>
              <option value='efectivo' selected>{t("facturacion.ultimo-paso")}</option>
              <option style={{ display: 'none' }}></option> {/* Valor predeterminado oculto */}
              <option style={{ display: 'none' }} value='transferencia'>{t("facturacion.transferencia")}</option>
            </select>
          </div>
          <div className='datosForm'>
            <hr />
            <h3>{t("facturacion.ultimo-paso")}</h3>
            <p>{t("facturacion.procesando-compra")}</p>
            <br />
            <hr />
          </div>
          <Button variant='success' type='submit' className='botonProcesarCompra'>
            {t("facturacion.procesar-compra")}
          </Button>
        </form>
      </div>
    </>
  );
};


