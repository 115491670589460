import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

import { LocalStorage } from "../Data/LocalStorage";
import { toast } from "react-toastify";
import { authContext } from "./AuthContext";

export const cartContext = createContext();

const urlApiCart = process.env.REACT_APP_API_CART;

const { Provider } = cartContext;

const CartContext = (props) => {
    const [cart, setCart] = useState([]);
    const [cartLocal, setCartLocal] = LocalStorage("cart", []);
    const [precompra, setPrecompra] = LocalStorage("precompra")
    const { desloguearInicio, desloguearse, token, idUser } = useContext(authContext);

    useEffect(() => {
        setCart(cartLocal);
    }, [cartLocal]);


    const getCartByUser = () => {
        axios
            .get(`${urlApiCart}/cart`, {
                params: { user: idUser },
                headers: { Authorization: "Bearer " + token },
            })
            .then((res) => {
                if (!res.data) {
                    setCartLocal([]);
                } else {
                    setCartLocal(res.data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response.status === 502 || err.response.status === 404)
                    return false;
                desloguearInicio();
            });
    };

    const isInCart = (Alias) => {
        return !cart ? null : cart.find((producto) => producto.Alias === Alias);
    };

    const addToCart = (producto, contador) => {
        if (isInCart(producto.Alias)) {
            /* ****************************PUT******************************* */
            setCart(
                cartLocal.map((item) =>
                    item.Alias === producto.Alias
                        ? { ...item, contador: item.contador + contador }
                        : item
                )
            );
            setCartLocal(
                cart.map((item) =>
                    item.Alias === producto.Alias
                        ? { ...item, contador: item.contador + contador }
                        : item
                )
            );

            axios
                .put(
                    `${urlApiCart}/cart/${producto.Alias}`,
                    {
                        data: { data: contador, user: idUser },
                    },
                    {
                        headers: { Authorization: "Bearer " + token },
                    }
                )
                .then(() => {
                    if (contador > 0) {
                        toast.success(`Ha agregado una unidad más de ${producto.Nombre} al carrito`, {
                            autoClose: 1000,
                            className: 'custom-toast',
                            onClose: () => {
                                // Recargar la página cuando el usuario confirme la notificación
                                window.location.reload();
                            },
                        });
                    } else if (contador -= 1) {
                        toast.success(`Ha quitado una unidad de ${producto.Nombre} al carrito`, {
                            autoClose: 1000,
                            className: 'custom-toast',
                            onClose: () => {
                                // Recargar la página cuando el usuario confirme la notificación
                                window.location.reload();
                            },
                        });
                    }
                })

                .catch(() => desloguearse());
        } else {
            /* ****************************POST******************************* */
            const datos = {
                Alias: producto.Alias,
                contador: contador,
                Nombre: producto.Nombre,
                Marca: producto.Marca,
                Precio: producto.Precio,
                Stock: producto.Stock,
                Moneda_Signo: producto.Moneda_Signo,
            };
            setCart([
                ...cartLocal,
                {
                    datos,
                },
            ]);
            setCartLocal([...cartLocal, { ...producto, contador }]);
            axios
                .post(
                    `${urlApiCart}/cart`,
                    {
                        data: { datos: datos, user: idUser },
                    },
                    {
                        headers: { Authorization: "Bearer " + token },
                    }
                )
                .then(() => {
                    console.log("Producto agregado correctamente");
                    toast.success(`Ha agregado ${producto.Nombre} al carrito`, {
                        position: "top-right",
                        className: 'custom-toast',
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                })
                .catch(() => desloguearse());
        }
    };

    const deleteItem = (Alias) => {
        const cartAux = cart.filter((item) => item.Alias !== Alias);
        setCart(cartAux);
        setCartLocal(cartLocal.filter((item) => item.Alias !== Alias));

        axios
            .delete(`${urlApiCart}/cart/${Alias}`, {
                params: { user: idUser },
                headers: { Authorization: "Bearer " + token },
            })
            .then(console.log("Producto eliminado correctamente"))
            .catch((error) => console.log(error));
    };

    const deleteByUser = () => {
        axios
            .delete(`${urlApiCart}/cart`, {
                params: { user: idUser },
                headers: { Authorization: "Bearer " + token },
            })
            .then((res) => console.log("Productos eliminados correctamente"))
            .catch((err) => console.log(err));
    };

    const emptyCart = () => {
        setCartLocal([]);
        setCart([]);
        localStorage.removeItem("cart");
    };

    const contextValue = {
        cart,
        cartLocal,
        setCartLocal,
        addToCart,
        deleteItem,
        deleteByUser,
        emptyCart,
        getCartByUser,
        precompra,
        setPrecompra,
    };

    return <Provider value={contextValue}>{props.children}</Provider>;
};

export default CartContext;
