import { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from "axios";

import { contexto } from './../Context/Context';
import { Unauthorized } from '../helpers/Unauthorized';
import { DatosPersonales } from './DatosPersonales';
import { ChangePassword } from '../Login/ChangePassword';
import { Historial } from './Historial';
import Cart from '../Carrito/Cart';
import './Profile.css';
import { BadGateway } from '../helpers/BadGateway';
import { authContext } from '../Context/AuthContext';

const apiPedidos = process.env.REACT_APP_API_PEDIDOS;

export const Profile = () => {
    const { token, user } = useContext(authContext);
    const [compras, setCompras] = useState();

    useEffect(() => {
        if (!user) return false;
        axios
            .get(`${apiPedidos}/orden_web/compras/cliente/${user.Id}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((res) => {
                setCompras(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!token) return <Unauthorized />;

    if (!compras) return <BadGateway />;

    return (
        <>
            <div className="divPerfil">
                <h1>Perfil de control de {user.Nombre}</h1>
                <h5>Desde esta pantalla puede administrar su cuenta y ver información sobre sus pedidos</h5>
            </div>
            <Tabs
                defaultActiveKey="historial"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="datos-personales" title="Datos personales">
                    <DatosPersonales user={user} />
                </Tab>
                <Tab eventKey="carrito" title="Carrito">
                    <Cart />
                </Tab>
                <Tab eventKey="historial" title="Historial de compras">
                    <Historial compras={compras} />
                </Tab>
                <Tab eventKey="psw" title="Cambiar contraseña">
                    <ChangePassword />
                </Tab>
            </Tabs>
        </>
    );
};
