import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import axios from 'axios'

import { contexto } from './../Context/Context'
import { Unauthorized } from '../helpers/Unauthorized'
import { Taxes } from "./Taxes";
import { CartRender } from "./CartRender";
import { Facturacion } from "./Facturacion";
import { SpinnerGif } from "../helpers/SpinnerGif";
import { SwalFire } from '../helpers/SwalFire'
import './Checkout.css'
import { cartContext } from '../Context/CartContext'
import { authContext } from '../Context/AuthContext'
import { useTranslation } from 'react-i18next'

const urlApiCheckout = process.env.REACT_APP_API_CHECKOUT

const Checkout = () => {
  const { cartLocal, deleteByUser } = useContext(cartContext)
  const { token, user, desloguearse } = useContext(authContext)
  const navigate = useNavigate()
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false)
  
  const [t, i18next] = useTranslation("global")

  const precompra = JSON.parse(localStorage.getItem('precompra'))

  if (!precompra) return <Unauthorized />

  const precompItems = precompra.items;
  const totalPrecio = precompItems.reduce((total, producto) => total + producto.Precio, 0)

  let taxPesos = [];
  let taxDolares = []
  function guardar(moneda, array) {
    for (let item in moneda) {
      if (moneda[item] > 0) {
        let nuevo = { [item]: moneda[item].toLocaleString() }
        array.push(nuevo);
      }
    }
  }
  let itemsMapeados = [];
  function mapearItems(items) {
    for (let i = 0; i < items.length; i++) {
      let info = {
        id: items[i].id,
        ID_Precomp: items[i].ID_Precomp,
        Alias: items[i].Alias,
        Moneda: items[i].Moneda,
        Precio: items[i].Precio,
        Cantidad: items[i].Cantidad,
      }
      itemsMapeados.push(info)
    }
  }

  guardar(precompra.Subtotal_Pesos, taxPesos);
  guardar(precompra.Subtotal_Dolares, taxDolares);
  mapearItems(precompra.items);

  const data = {
    carrito: {
      precompra: {
        ID: precompra.ID,
        ID_CLIENTE: user.Id,
        name_: user.Nombre,
        lastname: user.Apellido,
        email: user.Email,
        dni: user.Cuit || '12345678',
        telefono: user.Domicilio_facturacion.Telefono,
        calle: user.Domicilio_facturacion.Domicilio,
        numero: user.Domicilio_facturacion.Domicilio,
        localidad: user.Domicilio_facturacion.Localidad,
        codigoPostal: user.Domicilio_facturacion.Codigo_postal,
        Codigo_Prov: precompra.Codigo_Prov,
        precioTotal: totalPrecio,
        estado: precompra.estado,
        created_at: precompra.created_at,
        modified_at: precompra.modified_at,
        Pais: precompra.Pais,
        tipo_entrega: info.entrega,
        cond_pago: info.pago,
        items: itemsMapeados
      },
      cliente: {
        ID_CLIENTE: user.Id,
        name_: user.Nombre,
        lastname: user.Apellido,
        email: user.Email,
        dni: user.Cuit || '12345678',
        telefono: user.Domicilio_facturacion.Telefono,
        calle: user.Domicilio_facturacion.Domicilio,
        numero: '1',
        localidad: user.Domicilio_facturacion.Localidad,
        codigoPostal: user.Domicilio_facturacion.Codigo_postal,
      },
      condicion_de_pago: info.pago,
      tipo_de_entrega: info.entrega,
      direccion_de_entrega: {
        direccion: user.Domicilio_facturacion.Domicilio,
        cod_postal: user.Domicilio_facturacion.Codigo_postal,
        Codigo_Prov: user.Domicilio_facturacion.Codigo_Prov,
        localidad: user.Domicilio_facturacion.Localidad,
        provincia: user.Domicilio_facturacion.Provincia || "Provincia",
        Pais: user.Domicilio_facturacion.Pais,
      }
    }
  }

  const infoInputs = (e) => {
    let name = e.target.name
    let value = e.target.value
    setInfo(values => ({ ...values, [name]: value }))
  }

  const goToSuccess = (param) => {
    navigate(`/success`, {
      state: {
        param
      }
    });
  }


  const enviarOrden = (e) => {
    e.preventDefault(e)
    if ((info.pago === undefined || info.pago === "") || (info.entrega === undefined || info.entrega === "")) {
      SwalFire(t("checkout.seleccione-metodo"), 'warning');
    }
    else {
      axios.post(`${urlApiCheckout}/pedido/success`, data,
        { headers: { 'Authorization': 'Bearer ' + token } }
      )
        .then(setLoad(true))
        .then(res => {
          if (res.status === 200) {
            console.log(res);
            goToSuccess(res.data);
            deleteByUser();
          }
        })
        .catch(err => {
          console.log(err.response)
          setLoad(false)
          SwalFire('Error', err.response.data.message, 'warning')
            .then(function () {
              if (err.response.status === 401) {
                desloguearse();
                navigate('/')
              }
              else return false
            })
        })
    }
  }

  if (load) return <SpinnerGif text={t("checkout.procesando-compra")} />

  if (!token) return <Unauthorized />

  return (
    <div className='divCheckout'>
      <h1>{t("checkout.resumen-compra")}</h1>

      <CartRender cartLocal={cartLocal} />

      <Taxes taxPesos={taxPesos} taxDolares={taxDolares} />

      <Facturacion user={user} info={info} enviarOrden={enviarOrden} infoInputs={infoInputs} />

      <Button variant='secondary' onClick={() => navigate('/cart')}>
        <i className="fa-sharp fa-solid fa-circle-chevron-left"></i>
        {t("checkout.volver-carrito")}
      </Button>
    </div>
  )
}

export default Checkout