import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BannerMicrositioHuawei = () => {

    return (
        <div style={{ marginBottom: '1em' }}>
            <AnimationOnScroll animateIn="animate__fadeIn">
                <a href="https://huawei.solutionbox.com.ar/" target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage src={process.env.PUBLIC_URL + "/banner/banner-huawei-home.webp"} alt="Imagen marcas" className='imagenesBody' />
                </a>
            </AnimationOnScroll>
        </div>
    );
}

export default BannerMicrositioHuawei;